<template>
  <div class="resetPassword xi">
    <nh-com></nh-com>
    <div class="" style="margin-top: 30rpx">
      <div class="banner-wrap">
        <div class=" " style="color: #666666; font-size: 16px"><router-link to="/index">首页</router-link><i class="el-icon-arrow-right"></i><a>辨证论治</a><i class="el-icon-arrow-right"></i><a style="color: #666666">辨证论治系统</a></div>
      </div>
    </div>
    <div class="startBox">
      <template v-if="!isStartTest">
        <!-- 添加测试人信息 -->
        <div class="addUser-div flex-center" v-if="addUser == 0">
          <div class="addUser-box">
            <div class="top-tip">为方便给您保存测试结果，建议你准确填写相关信息</div>
            <div class="center-form">
              <el-form ref="answerUser" :model="answerUser" label-width="170px" :rules="rules">
                <el-form-item label="请输入您的姓名:" prop="answerUserName">
                  <el-input class="w-18" v-model="answerUser.answerUserName"></el-input>
                </el-form-item>
                <el-form-item label="选择您的性别:" prop="answerSex">
                  <el-radio-group v-model="answerUser.answerSex">
                    <el-radio :label="0">男</el-radio>
                    <el-radio :label="1">女</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="选择您的年龄:" prop="answerAge">
                  <el-radio-group v-model="answerUser.answerAge" class="age-radio">
                    <el-radio :label="0">6岁以下</el-radio>
                    <el-radio :label="1">7-12岁</el-radio>
                    <el-radio :label="2">13-17岁</el-radio>
                    <el-radio :label="3">18-45岁</el-radio>
                    <el-radio :label="4">46-69岁</el-radio>
                    <el-radio :label="5">69岁以上</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="请输入您的手机号:" prop="answerPhone">
                  <el-input class="w-18" v-model="answerUser.answerPhone"></el-input>
                </el-form-item>
                <el-form-item label="请输入您的基础病:" prop="diseaseName">
                  <el-input class="w-18" v-model="answerUser.diseaseName"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button class="btn1" @click="addUser = 1"><span class="text">取消</span></el-button>
                  <el-button class="btn" @click="saveBtn('answerUser')"><span class="text">提交</span></el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>

        <!-- 选择测试人 -->
        <div class="" v-if="addUser == 1" style="width: 100%">
          <div class="flex-end" style="margin: 0 auto 15px">
            <el-input class="searchInput" v-model="keyWord" style="width: 60%" placeholder="搜索测试对象..." clearable @keyup.enter.native="search"> <i slot="prefix" class="el-input__icon el-icon-search"></i></el-input>
          </div>
          <div class="selectUser-div flex-center">
            <div class="selectUser-box">
              <div class="userList-div flex-start flex-wrap">
                <div class="item-div flex-center-columns" @click="addUser = 0">
                  <div class="addBtn">＋ 新增测试对象</div>
                </div>
                <div class="item-div flex-center-columns" v-for="(item, index) in answerUserList" :key="index" :class="{ active: selectAnswerUser.id == item.id }" @click="selectAnswerUser = item">
                  <div class="userName oneLines">{{ item.answerUserName }}</div>
                  <div class="userInfo">{{ item.answerSex ? "女" : "男" }} {{ item.answerAgeDesc }} {{ item.answerPhoneDesc }}</div>
                  <div class="userInfo">基础病：{{ item.diseaseName ? item.diseaseName : '--' }}</div>
                  <div class="topDel-div">
                    <i class="el-icon-edit" @click="editAnswerUser(item)" style="margin-right: 5px">编辑</i>
                    <i class="el-icon-delete" @click="delAnswerUser(item, index)">删除</i>
                  </div>
                </div>
              </div>
              <div class="flex-end zsureBtn">
                <el-button v-if="selectAnswerUser" class="btn1" @click="goAnswerStaging()"
                  ><span class="text">未完成({{ AnswerStagingList.length }})</span></el-button
                >
                <el-button v-if="selectAnswerUser" class="btn" @click="goStart()"><span class="text">选择症状</span></el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="" v-if="addUser == 2" style="width: 100%">
          <div class="selectUser-div flex-center">
            <div class="selectUser-box">
              <div class="userList-div flex-wrap" style="display: flex">
                <div class="item-div1" v-for="(item, index) in AnswerStagingList" :key="index">
                  <div class="flex-s-b">
                    <div class="" style="display: flex; align-items: center">
                      <div class="titleBoxTextimage" @click.stop="goChooseCheck(item)">
                        <img :src="item.checked ? urlCheckM : noUrlCheckM" />
                      </div>
                      <div style="margin-left: 10px" class="userNameBox">{{ item.answerUserInfo.answerUserName }}</div>
                      <div class="userInfo">{{ item.answerUserInfo.answerSex ? "女" : "男" }} {{ item.answerUserInfo.ageContent }} {{ item.answerUserInfo.showanswerPhone }}</div>
                    </div>
                    <div class="delBox" @click.stop="gostartBox(item)">继续</div>
                  </div>
                  <div class="userNameBox1" style="color: #00c4ac; margin-left: 35px">主症:{{ item.answerName }}</div>
                  <div class="flex-s-b" style="margin-left: 35px">
                    <div class="flex-s-b-r">
                      <div class="" style="display: flex; align-items: center">
                        <div class="userNameBox1" style="color: #009583">{{ item.questionIndex }}</div>
                        <div class="userNameBox1">{{ "/" + item.questionTotal }}</div>
                        <div class="userInfo" style="margin-left: 10px">{{ item.answerDate | timefilters }}</div>
                      </div>

                      <el-progress :percentage="item.percentnum"></el-progress>
                    </div>

                    <div class="delBox1" @click.stop="godelBox(item)">
                      <i class="el-icon-delete"></i>
                      删除
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-end zsureBtn">
                <div style="display: flex; align-items: center" @click="allSelect">
                  <div class="titleBoxTextimage" style="margin-right: 10px">
                    <img :src="cssbtn ? urlCheckM : noUrlCheckM" />
                  </div>
                  全选
                </div>

                <div class="delBox" @click.stop="goalldelBox" style="margin-left: 40px">删除</div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="startBoxleft" v-if="isStartTest">
        <div class="drugTitle">
          <div class="drugTitle1">选择症状</div>
          <div style="width: 30%" @click="goSearch">
            <div class="zdrugInputclass">请输入症状名...<i class="el-icon-search zdrugInputIcon"></i></div>
          </div>
        </div>
        <div class="" style="margin-top: 10px">
          <div class="" style="margin-top: 15px" v-if="categoryList.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in categoryList" :key="index" style="margin-top: 10px">
                <div :class="categoryindex == index ? 'goodTagCheck' : 'goodTag'" @click="gotext(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <div class="" style="margin-top: 15px" v-if="categoryList1.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in categoryList1" :key="index" style="margin-top: 10px">
                <div :class="categoryindex1 == index ? 'goodTagCheck' : 'goodTag'" @click="gotext1(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <div class="" style="margin-top: 15px" v-if="categoryList2.length > 0">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in categoryList2" :key="index" style="margin-top: 10px">
                <div :class="categoryindex2 == index ? 'goodTagCheck' : 'goodTag'" @click="gotext2(item, index)">
                  {{ item.categoryName }}
                </div>
              </div>
            </div>
          </div>
          <!-- 主症列表 -->
          <div v-if="DiagnosiscategoryList.length > 0" class="" style="margin-top: 15px">
            <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
            <div class="" style="display: flex; flex-wrap: wrap">
              <div class="" v-for="(item, index) in DiagnosiscategoryList" :key="index" style="margin-top: 10px">
                <div :class="Diagnosiscategoryindex == index ? 'goodTagCheck' : 'goodTag'" @click="gotextDiagnosis(item, index)">
                  {{ item.primarySymptomName }}
                </div>
              </div>
              <div style="margin-top: 10px">
                <div v-if="pageIndex < pageCount" class="goodTag" @click="gotextDiagnosiMore">更多...</div>
              </div>
            </div>
          </div>
          <el-dialog title="详情" :visible.sync="dialogVisibleDetail" width="80%">
            <div class="dialogTitle">
              {{ selfDiagnosisPrimarySymptomDetail.primarySymptomName }}
            </div>
            <div class="textdetail" v-if="selfDiagnosisPrimarySymptomDetail.symptomDescription">
              <div class="">
                <p v-html="selfDiagnosisPrimarySymptomDetail.description.replace(/\n/g, '</p> <p>')"></p>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisibleDetail = false">取 消</el-button>
              <el-button type="warning" @click="goStartSure">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </div>
      <!-- <div class="startBoxright">
                <div class="" style="padding-bottom: 25px;max-height: 720px; overflow: hidden; overflow-y:auto;">
                    <div class="flexlr" style="align-items: center;padding: 0 5px;">
                        <div class="" style="font-size: 18px;font-weight: bold;">
                            历史问诊记录
                        </div>
                        <div class="" style="font-size: 16px;color: #666666;cursor: pointer;" @click="godetail1">
                            更多<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class="endboxright" v-for="(item,index) in UserSelfDiagnosisRecordList" :key="index" @click="goUserSelf(item)">
                        <div class="flexlr" style="align-items: center;">
                            <div class="" style="font-size: 16px;font-weight: bold;display: flex;align-items: center;">
                                <div class="ring">
                                    
                                </div>
                                <div class="" style="margin-left: 10px;">
                                    {{item.gender ? '男' : '女'}}
                                </div>
                                <div class="" style="margin-left: 10px;">
                                    {{item.age}}
                                </div>
                            </div>
                        </div>
                        <div class="" style="font-size: 15px;color: #D38539;padding-left:22px;">
                            您选择的主症是：{{item.primarySymptomName}}
                        </div>
                        <div class="symptomsText" >
                            <span v-for="(itemsym,indexsym) in item.symptoms" :key="indexsym" style="color:#DF7D1F;"> {{'【' + itemsym + '】'}}</span>
                        </div>
                        <div class="" style="text-align: right;font-size: 12px;color: #999999;">
                            {{item.diagnosisTime}}
                        </div>
                    </div>
                    <div v-if="UserSelfDiagnosisRecordList.length == 0" class="nodataimg">
                        <img src="@/assets/img/zdrugConsult/newnodata.png" style="width:209px;height:114px" alt="">
                    </div>
                </div>
            </div> -->
    </div>
    <!-- 症状弹窗 -->
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="50%" center>
      <div class="" style="text-align: right; padding-right: 30px; margin-top: 30px">
        <el-button round type="warning" @click="goStartSure">确定</el-button>
      </div>
    </el-dialog>
    <!-- 搜索 -->
    <el-dialog title="提示" :visible.sync="searchDialogVisible" width="50%" center>
      <div class="" style="margin-top: 10px">
        <!-- 主症列表 -->
        <div class="" style="margin-top: 15px">
          <div class="" style="font-size: 16px; font-weight: bold">症状分类</div>
          <div class="" style="display: flex; flex-wrap: wrap">
            <div class="" v-for="(item, index) in DiagnosiscategoryList1" :key="index" style="margin-top: 10px">
              <div :class="Diagnosiscategoryindex == index ? 'goodTagCheck' : 'goodTag'" @click="gotextDiagnosis(item, index)">
                {{ item.primarySymptomName }}
              </div>
            </div>
            <div style="margin-top: 10px">
              <div v-if="pageIndex1 < pageCount1" class="goodTag" @click="gotextDiagnosiMore1">更多...</div>
            </div>
          </div>
        </div>
        <div class="textdetail" v-if="selfDiagnosisPrimarySymptomDetail.symptomDescription">
          <div class="el-icon-warning-outline" style="color: #f6a11f; margin-top: 5px"></div>
          <div class="" style="font-size: 14px; color: #666666; margin-left: 5px">
            <p v-html="selfDiagnosisPrimarySymptomDetail.description.replace(/\n/g, '</p> <p>')"></p>
          </div>
        </div>
      </div>
      <div class="" style="text-align: right; padding-right: 30px; margin-top: 30px">
        <el-button round type="warning" @click="goStartSure">确定</el-button>
      </div>
    </el-dialog>
    <ft-com></ft-com>
  </div>
</template>

<script>
import ftCom from "../../components/footer";
import nhCom from "../../components/navheader";
import { zdrugconsultationMain } from "../../components/zdrugconsultation/zdrugconsultation";
import { Test } from "../../components/company/test";
export default {
  name: "resetPassword",
  components: {
    ftCom,
    nhCom,
  },
  data() {
    var zdrugconsultation = new zdrugconsultationMain(this.TokenClient, this.Services.Disease);
    var test = new Test(this.TokenClient, this.Services.Statics);
    return {
      testDomain: test,
      zdrugconsultationMain: zdrugconsultation,
      nam: "123",
      startShow: true,
      sexShow: false,
      sexName: "",
      sexShowName: "男",
      sexindex: 0,
      ageShow: false,
      ageName: "",
      ageShowName: "",
      ageindex: 1,
      sexList: [{ name: "男" }, { name: "女" }],
      showPrimarySymptomName: "",
      primarySymptomNameText: "",
      opcategoryindex: -1,
      centerDialogVisible: false,
      categoryList: [],
      categoryindex: -1,
      categoryList1: [],
      categoryindex1: -1,
      categoryList2: [],
      categoryindex2: -1,
      DiagnosiscategoryList: [],
      Diagnosiscategoryindex: -1,
      selfDiagnosisPrimarySymptomDetail: {},
      selfId: "",
      questindex: 0,
      questionList: [],
      processList: [],
      recordId: "",
      resultList: {},
      UserSelfDiagnosisRecordList: [],
      http: "",
      imgtextindex: -1,
      optionname: "",
      pageIndex: 1,
      pageCount: 1,
      selfDiagnosisId: "",
      searchDialogVisible: false,
      pageIndex1: 1,
      pageCount1: 1,
      DiagnosiscategoryList1: [],
      dialogVisibleDetail: false,
      selfSex: "",
      selfAge: "",
      quename: "",
      userInfo: this.$store.state.localData.userInfo,
      answerUser: {
        userId: 0,
        userName: "",
        answerUserName: "",
        answerSex: 0,
        answerAge: 0,
        answerPhone: "",
        diseaseName: "",
      }, //测试对象
      selectAnswerUser: "", //测试对象
      answerUserList: [], //测试对象list
      addUser: 1,
      rules: {
        answerUserName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        answerPhone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^((0\d{2,3}-\d{7,8})|(1[3456789]\d{9}))$/, message: "请输入合法手机号/电话号", trigger: "blur" },
        ],
      },
      isStartTest: false, //是否开始测试
      keyWord: "",
      isAnswerStaging: false,
      AnswerStagingList: [],
      urlCheckM: require("@/assets/img/zdrugConsult/check.png"),
      noUrlCheckM: require("@/assets/img/zdrugConsult/znocheck.png"),
      cssbtn:false,
      // 门诊参数
      mztype:  this.$route.query.type ? this.$route.query.type : '',
      answerUserName:  this.$route.query.answerUserName ? this.$route.query.answerUserName : '',
      answerPhone:  this.$route.query.answerPhone ? this.$route.query.answerPhone : '',
      userListChoose:''
    };
  },
  mounted() {
    if (this.$route.query.ZStype == '1') {
      this.isStartTest = true
      this.goStart()
    }
    this.getMyselfAnswerInfo()
    this.http = this.Services.Statics;
    this.userListChoose = window.sessionStorage.getItem('chooseUser') ? JSON.parse(window.sessionStorage.getItem('chooseUser')) : ''
  },
  methods: {
    goalldelBox() {
      let params = [];
      for (var i = 0; i < this.AnswerStagingList.length; i++) {
        if (this.AnswerStagingList[i].checked) {
          params.push(this.AnswerStagingList[i].id);
        }
      }
      this.$confirm("确认删除此数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.testDomain.deleteAnswerStaging(
            params.toString(),
            res => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getAnswerStagingInfoData();
            },
            error => {
              console.log("请求失败!请刷新重试:", error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    gostartBox(item) {
      this.$router.push({
        path: "/zdrugconsultation/zDrugProgress",
        query: {
          id: this.selfId,
          selectAnswerUser: this.selectAnswerUser,
          age: this.selfAge,
          sex: this.selfSex,
          name: this.quename,
          isgoId: item.id,
        },
      });
    },
    godelBox(e) {
      this.$confirm("确认删除此数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.testDomain.deleteAnswerStaging(
            e.id,
            res => {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getAnswerStagingInfoData();
            },
            error => {
              console.log("请求失败!请刷新重试:", error);
            }
          );
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    allSelect() {
      this.cssbtn = !this.cssbtn;
      if (this.cssbtn == true) {
        for (var i = 0; i < this.AnswerStagingList.length; i++) {
          this.AnswerStagingList[i]["checked"] = true;
        }
      } else {
        for (var i = 0; i < this.AnswerStagingList.length; i++) {
          this.AnswerStagingList[i]["checked"] = false;
        }
      }
    },
    goChooseCheck(e) {
      for (let j = 0; j < this.AnswerStagingList.length; j++) {
        if (e.id == this.AnswerStagingList[j].id) {
          this.AnswerStagingList[j].checked = !this.AnswerStagingList[j].checked;
          // this.$set(this.AnswerStagingList[j], 'checked' , !this.AnswerStagingList[j].checked, )
        }
      }
      const allcartList = this.AnswerStagingList.every(item => {
        return item.checked == true;
      });
      if (allcartList) {
        this.cssbtn = true;
      } else {
        this.cssbtn = false;
      }
    },
    goAnswerStaging() {
      if (this.AnswerStagingList.length == 0) {
        this.$message({
          message: "暂无未完成数据",
          type: "warning",
        });
        return;
      }
      this.addUser = 2;
      this.getAnswerStagingInfoData();
    },
    search() {
      this.getMyselfAnswerInfo();
    },
    /**
     * 获取测试用户
     */
    getMyselfAnswerInfo() {
      this.testDomain.MyselfAnswerInfo(
        this.keyWord,
        res => {
          this.getAnswerStagingInfoData();
          this.answerUserList = [];
          this.selectAnswerUser = {};
          this.answerUserList = res.data;
          this.answerUserList.forEach((item,index) => {
            if (item.answerAge == 0) {
              item.answerAgeDesc = "6岁以下";
            } else if (item.answerAge == 1) {
              item.answerAgeDesc = "7-12岁";
            } else if (item.answerAge == 2) {
              item.answerAgeDesc = "13-17岁";
            } else if (item.answerAge == 3) {
              item.answerAgeDesc = "18-45岁";
            } else if (item.answerAge == 4) {
              item.answerAgeDesc = "46-69岁";
            } else {
              item.answerAgeDesc = "69岁以上";
            }
            item.answerPhoneDesc = item.answerPhone.substr(0, 3) + "****" + item.answerPhone.substr(7);
            if (this.userListChoose.type == 'menzhen') { 
              if (item.answerUserName == this.userListChoose.answerUserName && item.answerPhone == this.userListChoose.answerPhone && item.answerSex == this.userListChoose.answerSex && item.answerAge == this.userListChoose.answerAge && item.diseaseName == this.userListChoose.diseaseName) {
                this.selectAnswerUser = this.answerUserList[index]
              }
            } else {
              this.selectAnswerUser = this.answerUserList[0];
            }
          });
     
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    getAnswerStagingInfoData() {
      this.testDomain.getAnswerStaging(
        "z0",
        res => {
          this.AnswerStagingList = res.data;
          for (let i = 0; i < this.AnswerStagingList.length; i++) {
            this.AnswerStagingList[i].answerUserInfo.showanswerPhone = this.AnswerStagingList[i].answerUserInfo.answerPhone.substr(0, 3) + "****" + this.AnswerStagingList[i].answerUserInfo.answerPhone.substr(7);
            if (this.AnswerStagingList[i].answerUserInfo.answerAge == 0) {
              this.AnswerStagingList[i].answerUserInfo["ageContent"] = "6岁以下";
            } else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 1) {
              this.AnswerStagingList[i].answerUserInfo["ageContent"] = "7-12岁";
            } else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 2) {
              this.AnswerStagingList[i].answerUserInfo["ageContent"] = "13-17岁";
            } else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 3) {
              this.AnswerStagingList[i].answerUserInfo["ageContent"] = "18-45岁";
            } else if (this.AnswerStagingList[i].answerUserInfo.answerAge == 4) {
              this.AnswerStagingList[i].answerUserInfo["ageContent"] = "46-69岁";
            } else {
              this.AnswerStagingList[i].answerUserInfo["ageContent"] = "69岁以上";
            }
            this.AnswerStagingList[i]["percentnum"] = (100 / this.AnswerStagingList[i].questionTotal) * this.AnswerStagingList[i].questionIndex;
            // this.AnswerStagingList[i]['checked'] = false
            this.$set(this.AnswerStagingList[i], "checked", false);
          }
        },
        error => {
          this.AnswerStagingList = [];
        }
      );
    },
    /**
     * 添加测试用户
     */
    saveBtn(formName) {
      this.$refs[formName].validate(val => {
        if (val) {
          if (this.answerUser.id) {
            this.testDomain.EditAnswerUser(
              this.answerUser,
              res => {
                this.$message({
                  type: "success",
                  message: "修改成功!",
                });
                this.addUser = 1;
                (this.answerUser = {
                  userId: 0,
                  userName: "",
                  answerUserName: "",
                  answerSex: 0,
                  answerAge: 0,
                  answerPhone: "",
                  diseaseName: "",
                }),
                  this.getMyselfAnswerInfo();
              },
              error => {
                console.log("请求失败!请刷新重试:", error);
              }
            );
          } else {
            this.answerUser.userId = this.userInfo.id;
            this.answerUser.userName = this.userInfo.userName;
            this.testDomain.AddAnswerUser(
              this.answerUser,
              res => {
                this.$message({
                  type: "success",
                  message: "添加成功!",
                });
                this.addUser = 1;
                (this.answerUser = {
                  userId: 0,
                  userName: "",
                  answerUserName: "",
                  answerSex: 0,
                  answerAge: 0,
                  answerPhone: "",
                  diseaseName: "",
                }),
                  this.getMyselfAnswerInfo();
              },
              error => {
                console.log("请求失败!请刷新重试:", error);
              }
            );
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 删除测试用户
     * @param {*} item
     * @param {*} i
     */
    delAnswerUser(item, i) {
      this.testDomain.RemoveAnswerUser(
        item.id,
        res => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.answerUserList.splice(i, 1);
          if (this.answerUserList.length == 0) {
            this.selectAnswerUser = "";
          }
        },
        error => {
          console.log("请求失败!请刷新重试:", error);
        }
      );
    },
    editAnswerUser(item) {
      this.addUser = 0;
      this.answerUser.id = item.id;
      this.answerUser.userId = item.userId;
      this.answerUser.userName = item.userName;
      this.answerUser.answerUserName = item.answerUserName;
      this.answerUser.answerSex = item.answerSex ? 1 : 0;
      this.answerUser.answerAge = item.answerAge;
      this.answerUser.answerPhone = item.answerPhone;
      this.answerUser.diseaseName = item.diseaseName;
    },
    goSearch() {
      this.$router.push({
        path: "/zdrugconsultation/zDrugSearch",
        query: {
          selectAnswerUser: this.selectAnswerUser,
        },
      });
      if (localStorage.getItem('selectAnswerUser2')) {
         localStorage.removeItem("selectAnswerUser2")
      }
      localStorage.setItem('selectAnswerUser2', JSON.stringify(this.selectAnswerUser));
    },
    gosearchCategory() {
      this.centerDialogVisible = false;
      this.DiagnosiscategoryList1 = [];
      this.searchDialogVisible = true;
      this.getSelfDiagnosisPrimarySymptomList1();
    },
    // 主症列表
    getSelfDiagnosisPrimarySymptomList1() {
      var _this = this;
      _this.pageIndex1 = 1;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptom(
        0,
        "%20",
        this.selectAnswerUser.answerSex,
        this.selectAnswerUser.answerAge,
        _this.pageIndex1,
        function (data) {
          _this.DiagnosiscategoryList1 = data.data.results;
          _this.pageCount1 = data.data.pageCount;
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList = [];
          }
        }
      );
    },
    gotextDiagnosiMore1() {
      var _this = this;
      _this.pageIndex1 = _this.pageIndex1 + 1;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptom(
        0,
        "%20",
        this.selectAnswerUser.answerSex,
        this.selectAnswerUser.answerAge,
        _this.pageIndex1,
        function (data) {
          _this.DiagnosiscategoryList1 = _this.DiagnosiscategoryList1.concat(data.data.results);
          _this.pageCount1 = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList1 = [];
          }
        }
      );
    },
    gotextDiagnosiMore() {
      var _this = this;
      _this.pageIndex = _this.pageIndex + 1;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptom(
        _this.selfDiagnosisId,
        "%20",
        this.selectAnswerUser.answerSex,
        this.selectAnswerUser.answerAge,
        _this.pageIndex,
        function (data) {
          _this.DiagnosiscategoryList = _this.DiagnosiscategoryList.concat(data.data.results);
          _this.pageCount = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList = [];
          }
        }
      );
    },
    goUserSelf(e) {
      this.$router.push({
        path: "/zdrugconsultation/zdrugDetail",
        query: {
          id: e.id,
        },
      });
    },
    // 症状分类
    goStart() {
      var _this = this;
      this.isStartTest = true;
      _this.zdrugconsultationMain.getSelfDiagnosisCategory(
        "",
        function (data) {
          _this.categoryList = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.categoryList = [];
          }
        }
      );
    },
    gotext(item, index) {
      this.categoryList1 = [];
      this.categoryindex1 = -1;
      this.categoryList2 = [];
      this.categoryindex2 = -1;
      this.DiagnosiscategoryList = [];
      this.Diagnosiscategoryindex = -1;
      this.selfDiagnosisPrimarySymptomDetail = {};
      this.categoryindex = index;
      if (item.isEnableChildren) {
        this.getSelfDiagnosisCategoryList1(item.categoryId);
      } else {
        this.getSelfDiagnosisPrimarySymptomList(item.categoryId);
      }
    },
    gotext1(item, index) {
      this.categoryList2 = [];
      this.categoryindex2 = -1;
      this.DiagnosiscategoryList = [];
      this.Diagnosiscategoryindex = -1;
      this.selfDiagnosisPrimarySymptomDetail = {};
      this.categoryindex1 = index;
      if (item.isEnableChildren) {
        this.getSelfDiagnosisCategoryList2(item.categoryId);
      } else {
        this.getSelfDiagnosisPrimarySymptomList(item.categoryId);
      }
    },
    gotext2(item, index) {
      this.DiagnosiscategoryList = [];
      this.Diagnosiscategoryindex = -1;
      this.selfDiagnosisPrimarySymptomDetail = {};
      this.categoryindex2 = index;
      if (item.isEnableChildren) {
        this.getSelfDiagnosisCategoryList3(item.categoryId);
      } else {
        this.getSelfDiagnosisPrimarySymptomList(item.categoryId);
      }
    },
    // 症状分类
    getSelfDiagnosisCategoryList1(e) {
      var _this = this;

      _this.zdrugconsultationMain.getSelfDiagnosisCategory(
        e,
        function (data) {
          _this.categoryList1 = data.data;
        },
        function (error) {
          if (error.statusCode == 404) {
            _this.categoryList1 = [];
          }
        }
      );
    },
    getSelfDiagnosisCategoryList2(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisCategory(
        e,
        function (data) {
          _this.categoryList2 = data.data;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.categoryList2 = [];
          }
        }
      );
    },
    gotextopMultiple(e) {
      e.checked = !e.checked;
      this.optionname = e;
    },
    // 主症列表
    getSelfDiagnosisPrimarySymptomList(e) {
      var _this = this;
      _this.selfDiagnosisId = e;
      _this.pageIndex = 1;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptom(
        e,
        "%20",
        this.selectAnswerUser.answerSex,
        this.selectAnswerUser.answerAge,
        _this.pageIndex,
        function (data) {
          _this.DiagnosiscategoryList = data.data.results;
          _this.pageCount = data.data.pageCount;
        },
        function (error) {
          console.log(error);
          if (error.statusCode == 404) {
            _this.DiagnosiscategoryList = [];
          }
        }
      );
    },
    // 主症详情
    getSelfDiagnosisPrimarySymptomDetailList(e) {
      var _this = this;
      _this.zdrugconsultationMain.getSelfDiagnosisPrimarySymptomDetail(
        e,
        function (data) {
          _this.selfDiagnosisPrimarySymptomDetail = data.data;
          _this.selfSex = data.data.filterSex;
          _this.selfAge = data.data.minAge;
          _this.quename = data.data.primarySymptomName;
          _this.dialogVisibleDetail = true;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    // 滚动到底部
    handleScrollBottom() {
      this.$nextTick(() => {
        let scrollElem = this.$refs.scrollDiv;
        scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: "smooth" });
      });
    },
    goStartSure() {
      this.dialogVisibleDetail = false;
      this.$router.push({
        path: "/zdrugconsultation/zDrugProgress",
        query: {
          id: this.selfId,
          selectAnswerUser: this.selectAnswerUser,
          age: this.selfAge,
          sex: this.selfSex,
          name: this.quename,
        },
      });
    },
    // 题库
    getSelfDiagnosisQuestionList() {
      var _this = this;
      let isGender = false;
      if (_this.sexShowName == "男") {
        isGender = true;
      } else {
        isGender = false;
      }
      _this.zdrugconsultationMain.getSelfDiagnosisQuestion(
        _this.selfId,
        isGender,
        _this.ageShowName,
        function (data) {
          for (let i = 0; i < data.data.length; i++) {
            data.data[i].arryIndex = 0;
            if (data.data[i].fileUrl) {
              data.data[i].fileUrl = JSON.parse(data.data[i].fileUrl);
            }
            for (let j = 0; j < data.data[i].selfDiagnosisQuestionOption.length; j++) {
              if (data.data[i].selfDiagnosisQuestionOption[j].fileUrl && data.data[i].selfDiagnosisQuestionOption[j].fileUrl != "null") {
                data.data[i].selfDiagnosisQuestionOption[j].fileUrl = JSON.parse(data.data[i].selfDiagnosisQuestionOption[j].fileUrl);
                data.data[i].imgTrue = true;
              }
              if (data.data[i].type == "Multiple") {
                data.data[i].selfDiagnosisQuestionOption[j].checked = false;
              }
            }
          }
          _this.questionList = data.data;
          _this.processList.push(_this.questionList[_this.questindex]);
          _this.handleScrollBottom();
        },
        function (error) {
          console.log(error);
        }
      );
    },
    gotextDiagnosis(item, index) {
      this.Diagnosiscategoryindex = index;
      this.showPrimarySymptomName = item.primarySymptomName;
      this.getSelfDiagnosisPrimarySymptomDetailList(item.id);
      this.selfId = item.id;
    },
    goresult() {
      this.centerDialogVisible = true;
    },
    gotexsex(e, index) {
      this.sexShowName = e.name;
      this.sexindex = index;
    },
    gosexSure() {
      this.sexName = this.sexShowName;
      this.sexShow = false;
      this.ageShow = true;
    },
    goAgeSure() {
      this.ageName = this.ageShowName;
      this.ageShow = false;
      this.getSelfDiagnosisQuestionList();
    },
    handleChangeAge(value) {
      this.ageShowName = value;
    },
    gotextop(e, index) {
      this.opcategoryindex = index;
      this.optionname = e;
    },
    gorightresult() {
      var _this = this;
      let params = [];
      let params1 = [];
      for (let i = 0; i < _this.processList.length; i++) {
        if (_this.processList[i].questionOptionList.length > 0) {
          for (let j = 0; j < _this.processList[i].questionOptionList.length; j++) {
            params1.push({
              questionName: _this.processList[i].description,
              questionFileUrl: JSON.stringify(_this.processList[i].fileUrl),
              questionOptionName: _this.processList[i].questionOptionList[j].description,
              questionOptionFileUrl: JSON.stringify(_this.processList[i].questionOptionList[j].fileUrl),
              symptom: _this.processList[i].questionOptionList.relationSymptoms,
              rank: _this.processList[i].rank,
            });
          }
        } else {
          params.push({
            questionName: _this.processList[i].description,
            questionFileUrl: JSON.stringify(_this.processList[i].fileUrl),
            questionOptionName: _this.processList[i].questionOptionList.description,
            questionOptionFileUrl: JSON.stringify(_this.processList[i].questionOptionList.fileUrl),
            symptom: _this.processList[i].questionOptionList.relationSymptoms,
            rank: _this.processList[i].rank,
          });
        }
      }
      let isGender = false;
      if (_this.sexName == "男") {
        isGender = true;
      } else {
        isGender = false;
      }
      let resultParams = {};
      resultParams.primarySymptomId = _this.selfId;
      resultParams.age = _this.ageName;
      resultParams.gender = isGender;
      resultParams.answerDiseaseName = this.selectAnswerUser.diseaseName;
      resultParams.recordInfo = params.concat(params1);
      _this.zdrugconsultationMain.getSelfDiagnosisQuestionSubmit(
        resultParams,
        function (data) {
          _this.zdrugconsultationMain.getUserSelfDiagnosisRecordResult(
            data.data.recordId,
            function (data) {
              _this.resultList = data.data;
              _this.resultList.diagnosisTime = _this.resultList.diagnosisTime.slice(0, 10);
              let contantList = [];
              for (let i = 0; i < _this.resultList.recordResults.length; i++) {
                contantList = _this.resultList.recordResults[i].pulseSymptoms.concat(_this.resultList.recordResults[i].symptoms.concat(_this.resultList.recordResults[i].tongueSymptoms));
                _this.resultList.recordResults[i].contantLists = contantList;
                let newsyndromeSymptoms1 = [];
                for (let k = 0; k < _this.resultList.recordResults[i].syndromeSymptoms.length; k++) {
                  newsyndromeSymptoms1.push({
                    name: _this.resultList.recordResults[i].syndromeSymptoms[k],
                    checked: false,
                  });
                }
                _this.resultList.recordResults[i].newsyndromeSymptoms = newsyndromeSymptoms1;
              }
              for (let m = 0; m < _this.resultList.recordResults.length; m++) {
                for (let n = 0; n < _this.resultList.recordResults[m].contantLists.length; n++) {
                  for (let j = 0; j < _this.resultList.recordResults[m].newsyndromeSymptoms.length; j++) {
                    if (_this.resultList.recordResults[m].newsyndromeSymptoms[j].name == _this.resultList.recordResults[m].contantLists[n]) {
                      _this.resultList.recordResults[m].newsyndromeSymptoms[j].checked = true;
                    }
                  }
                }
              }
            },
            function (error) {
              _this.resultList = data.data;
              console.log(error);
            }
          );
        },
        function (error) {
          console.log(error);
        }
      );
    },
    chooseAnswer(e) {
      if (this.optionname == "") {
        this.$message.error("您尚未选择内容");
        return;
      }
      this.processList[this.processList.length - 1].questionOptionList = this.optionname;
      this.opcategoryindex = -1;
      this.imgtextindex = -1;
      if (this.questindex < this.questionList.length - 1) {
        this.questindex++;
        this.processList.push(this.questionList[this.questindex]);
        this.optionname = "";
      } else {
        this.questindex = this.questionList.length;
        this.gorightresult();
      }
      this.handleScrollBottom();
    },
    chooseAnswerMultiple() {
      if (this.optionname == "") {
        this.$message.error("您尚未选择内容");
        return;
      }
      let optionnameList = [];

      for (let i = 0; i < this.processList[this.processList.length - 1].selfDiagnosisQuestionOption.length; i++) {
        if (this.processList[this.processList.length - 1].selfDiagnosisQuestionOption[i].checked) {
          optionnameList.push(this.processList[this.processList.length - 1].selfDiagnosisQuestionOption[i]);
        }
      }
      this.processList[this.processList.length - 1].questionOptionList = optionnameList;
      console.log(this.processList[this.processList.length - 1].questionOptionList);
      this.opcategoryindex = -1;
      this.imgtextindex = -1;
      this.optionname = "";
      if (this.questindex < this.questionList.length - 1) {
        this.questindex++;
        this.processList.push(this.questionList[this.questindex]);
      } else {
        this.questindex = this.questionList.length;
        this.gorightresult();
      }
      this.handleScrollBottom();
    },
    godetail(e) {
      this.$router.push({
        path: "/zdrugconsultation/zdrugsynDetail",
        query: {
          id: e.syndromeId,
        },
      });
    },

    godetail1() {
      this.$router.push({
        path: "/personal/SelfTest",
        query: {
          type: "2",
        },
      });
    },
    agreeChange(e) {
      this.optionname = e;
    },
    gosearchimgMultiple(e) {
      console.log(e);
      e.checked = !e.checked;
      this.optionname = e;
    },
    gosearchimg(e, index) {
      this.imgtextindex = index;
      this.optionname = e;
    },
  },
};
</script>

<style scoped>
@import "../../assets/css/footer.css";
::v-deep .el-progress__text {
  display: none;
}
.titleBoxTextimage {
  width: 24px;
  height: 24px;
}
.titleBoxTextimage img {
  width: 100%;
  height: 100%;
}
.flex-s-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-s-b-r {
  display: flex;
  flex-direction: column;
  width: 60%;
}
.delBox1 {
  width: 80px;
  /* height: 30px; */
  font-size: 16px;
  /* color: #FFFFFF; */
  /* background: #00C4AC; */
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
}
.delBox {
  width: 80px;
  /* height: 30px; */
  font-size: 16px;
  color: #ffffff;
  background: #00c4ac;
  border-radius: 15px;
  text-align: center;
  padding: 5px 0;
}
.userNameBox1 {
  font-size: 18px;
  color: #666666;
}
.userNameBox {
  width: 120px;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
  text-overflow: ellipsis; /*超出部分文字以...显示*/
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #333333;
}
.banner-wrap {
  position: relative;
  width: 1200px;
  height: 100%;
  margin: 0 auto;
}
.leftbox {
  display: flex;
  align-items: center;
}
.leftMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #f4f4f4;
  border-radius: 0px 15px 15px 15px;
  margin-left: 10px;
}
.resultMessage {
  width: 100%;
  margin-top: 15px;
  padding: 15px;
  margin-left: 10px;
  border: 1px solid #f2f2f2;
  box-shadow: 0px 5px 10px 0px rgba(153, 153, 153, 0.16);
  border-radius: 16px;
}
.rightbox {
  display: flex;
  justify-content: flex-end;
  text-align: right;
}
.rightMessage {
  max-width: 240px;
  margin-top: 15px;
  font-size: 14px;
  padding: 15px;
  background-color: #ee9e51;
  border-radius: 15px 0px 15px 15px;
  color: #fff;
}
.startBox {
  width: 1200px;
  margin: 30px auto;
  padding: 40px;
  box-shadow: 0px 6px 10px 0px rgba(204, 204, 204, 0.18);
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: url("../../assets/img/zdrugConsult/zdrugStartChart.jpg");
}

.startBox .addUser-div {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  height: auto;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 40px;
  padding: 20px 10px;
}

.startBox .addUser-div .addUser-box {
  background: #fff;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  border-radius: 40px;
}

.addUser-div .top-tip {
  font-size: 15px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #db3a3a;
  margin-bottom: 20px;
}

.addUser-div .center-form {
  width: 100%;
}

.addUser-div .center-form /deep/ .el-form {
  width: 80%;
  margin: 0 auto;
}

.addUser-div .center-form /deep/ .el-form-item__label {
  color: #01c2ac;
  font-weight: 400;
  font-size: 17px;
}

.addUser-div .center-form .age-radio /deep/ .el-radio {
  line-height: 40px;
}

.addUser-div .center-form .age-radio {
  width: 80%;
}

.addUser-div .btn,
.selectUser-div .btn {
  width: 160px;
  height: 50px;
  background: linear-gradient(90deg, #03cdb4, #00a28e);
  box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.21);
  border-radius: 25px;
}
.addUser-div .btn1,
.selectUser-div .btn1 {
  width: 160px;
  height: 50px;
  background: #ffffff;
  /* box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.21); */
  border-radius: 25px;
}
.btn1 .text {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.btn .text {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}
.zsureBtn {
  margin-right: 20px;
  margin-top: 20px;
}

.addUser-div .w-18 {
  width: 80%;
}

.selectUser-div {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  height: auto;

  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 40px;
  padding: 30px 0;
}

.searchInput ::v-deep() .el-input__inner {
  border-radius: 20px;
  height: 50px;
  line-height: 50px;
}
.selectUser-box {
  position: relative;
}
.selectUser-div .selectUser-box,
.test-div .test-box {
  width: 90%;
}
.userList-div {
  height: 400px;
  overflow-y: auto;
}
.selectUser-div .userList-div,
.test-div .testList-div {
  width: 100%;
}

.selectUser-div .active {
  border: 1px solid #00cbb5 !important;
}

.selectUser-div .item-div:hover {
  border: 1px solid #00cbb5 !important;
}
.selectUser-div .item-div1:hover {
  border: 1px solid #00cbb5 !important;
}
.selectUser-div .item-div {
  width: 27%;
  height: 112px;
  margin-right: 2%;
  margin-bottom: 20px;
  border: 1px solid #e3e6e6;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 30px;
  cursor: pointer;
  align-items: flex-start !important;
  padding: 10px 20px;
  position: relative;
}
.selectUser-div .item-div1 {
  width: 43%;
  height: 140px;
  margin-right: 2%;
  margin-bottom: 20px;
  border: 1px solid #e3e6e6;
  box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
  border-radius: 30px;
  cursor: pointer;
  align-items: flex-start !important;
  padding: 10px 20px;
  position: relative;
}
/* .selectUser-div .item-div1:nth-child(3n) {
  margin-right: 0;
} */
.selectUser-div .item-div:nth-child(3n) {
  margin-right: 0;
}

.selectUser-div .userName {
  width: 100%;
  font-size: 28px;
  font-family: Source Han Sans CN;
  font-weight: 500;
}

.selectUser-div .item-div .addBtn {
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #009583;
}

.selectUser-div .userInfo {
  color: #999999;
  font-size: 16px;
}

.selectUser-div .topDel-div {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #009583;
  cursor: pointer;
}
.startBoxleft {
  position: relative;
  width: 100%;
  min-height: 720px;
  background: #ffffff;
  border-radius: 20px;
  padding: 30px;
}
.startBoxleftTop {
  height: 500px;
  overflow: hidden;
  overflow-y: auto;
}
/*滚动条样式*/
.startBoxleftTop::-webkit-scrollbar {
  width: 4px;
}
.startBoxleftTop::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: rgba(0, 0, 0, 0.2);
}
.startBoxleftTop::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}
.startBoxleftBot {
  /* overflow-x:scroll; */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 15px;
  border-top: 1px solid #e6e6e6;
  position: relative;
}
.startBoxleftBotn {
  margin-top: 12px;
  text-align: right;
  margin-right: 30px;
}
.startBoxright {
  margin-left: 5%;
  width: 30%;
  height: 720px;
  padding: 30px;
  background: #ffffff;
  border-radius: 20px;
  position: relative;
}
.surebtn {
  background: linear-gradient(180deg, #ffb163, #ee9e51);
  font-size: 16px;
  color: #ffffff;
  padding: 17rpx 0;
  width: 100%;
  text-align: center;
}
.goodTagCheck {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #ffe6cd;
  border-radius: 20px;
  margin-left: 10px;
  color: #df7d1f;
  margin-top: 10px;
  cursor: pointer;
}
.goodTag {
  font-size: 18px;
  text-align: center;
  padding: 5px 32px;
  background-color: #f2f2f2;
  border-radius: 20px;
  margin-left: 10px;
  margin-top: 10px;
  cursor: pointer;
}
/* 症状 */
.textdetail {
  padding: 15px;
  background-color: #f2f2f2;
  border-radius: 12px;
}
.viptable {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}
.viptableY {
  width: 100%;
  height: 150px;
  overflow: hidden;
  overflow-y: auto;
}
.endbox {
  margin-top: -50px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
}
.endboxright {
  margin-top: 15px;
  padding: 10px 15px;
  background: #ffffff;
  box-shadow: 0px 6px 0px 0px #f3d6bc;
  border-radius: 10px;
  border: 1px solid #f3d6bc;
  cursor: pointer;
}
.ring {
  width: 12px;
  height: 12px;
  border: 3px solid #cf7f31;
  box-shadow: 0px 2px 3px 0px rgba(202, 127, 55, 0.3);
  border-radius: 50%;
}
.endbox1 {
  margin-top: 20px;
  padding: 15px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(214, 214, 214, 0.3);
  border-radius: 10px;
  position: relative;
  border: 1px solid #ffe5cb;
}
.ring1 {
  width: 6px;
  height: 6px;
  background: #df7d1f;
  border-radius: 50%;
}
.ring2 {
  width: 6px;
  height: 6px;
  background: #3bc8d9;
  border-radius: 50%;
}
.ring3 {
  width: 6px;
  height: 6px;
  background: #68caa9;
  border-radius: 50%;
}
.boxtag2 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background-color: #3bc8d9;
  font-size: 24rpx;
}
.boxtag3 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background: #68caa9;
  font-size: 12px;
}
.boxtag1 {
  position: absolute;
  top: 0;
  right: 0;
  padding: 7px;
  color: #ffffff;
  border-radius: 10px 0px 10px 0px;
  background-color: #f6a11f;
  font-size: 12px;
}
.flexlr {
  display: flex;
  justify-content: space-between;
}
.resultimg {
  width: 100%;
  height: 200px;
  background-size: cover;
  background-image: url("../../assets/img/zdrugConsult/resultback.png");
}
.rightBottom {
  padding: 10px 25px;
  background: #f0f2f2;
  border-radius: 25px;
  font-size: 13px;
  color: #999999;
  text-align: center;
}
.rightBottomBox {
  border-top: 1px solid #e6e6e6;
  padding: 20px;
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
}
.radioBox {
  margin: 10px 0;
  font-size: 18px;
}
.imgTag {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-left: 10px;
  border-radius: 8px;
}
.imgTagCheck {
  background-color: #feaf62;
  /* opacity: 0.5; */
  padding: 5px;
}
.textCheck {
  color: #df7d1f;
}
.textBox {
  display: flex;
  flex-wrap: wrap;
}
.symptomsText {
  font-size: 15px;
  color: #666666;
  margin: 8px 0;
  padding-left: 22px;
  /* overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical; */
}
.itembox {
  width: 50%;
}
.nodataimg {
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.hide-tooltip {
  max-width: 400px;
  /* visibility: hidden; */
}
.newChecked {
  color: #df7d1f;
}
.drugTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-right: 40px;
  align-items: center;
}
.drugTitle1 {
  font-size: 28px;
  color: #333333;
}
.zdrugInputclass {
  width: 100%;
  padding: 10px 20px;
  background: #fafafa;
  border: 1px solid #e7a365;
  border-radius: 20px;
  font-size: 20px;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.zdrugInputIcon {
  padding: 7px 17px;
  border-radius: 17px;
  background-color: #e7a365;
  color: #fff;
}
::v-deep .el-dialog {
  width: 48% !important;
  margin-top: 20vh !important;
}
.dialogTitle {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
</style>
<style>
.personal-center {
  display: none;
}
</style>
